(function ($) {
  /**
   * Basic sub-block toggle for gnav utility templates
   * inside this formatter
   * sub-templates need to have a trigger with class .js-gnav-util-trigger
   * the following div should have .js-gnav-util__content .hidden
   *
   * NOTE: the cart utility link manages its' own toggle so we can render
   * cart content via js when opening
   * ~/darphin_base/template_api/navigation/gnav_cart_v1/gnav_cart_v1.js
   *
   */
  Drupal.behaviors.utilityNavFormatter = {
    attach: function (context) {
      var $block = $('.gnav-util-formatter__item', context);
      var $trigger = $('.js-gnav-util-trigger', $block);
      var $menuTrigger = $('.js-gnav-mobile-trigger', $block);
      var $closeTrigger = $('.js-gnav-util-close', $block);
      var $blockContent = $('.js-gnav-util__content', $block);
      var $menucloseTrigger = $('.js-gnav-mobile-close', $block);
      var $mobileNavOverlay = $('.mobile-nav__overlay');
      var $mobileNav = $('.site-header');

      $mobileNav.after('<div class="mobile-nav__overlay"></div>');

      $trigger.on('click', function (event) {
        event.preventDefault();
        $('body').removeClass('mobile-gnav-active');
        $blockContent.not($(this).next('.js-gnav-util__content')).addClass('hidden');
        $(this).next('.js-gnav-util__content').toggleClass('hidden');
      });

      // hamburger adds a body class so we can toggle main nav
      $menuTrigger.on('click', function (event) {
        event.preventDefault();
        $blockContent.addClass('hidden');
        $menuTrigger.addClass('hidden');
        $menucloseTrigger.removeClass('hidden');
        $('body').toggleClass('mobile-gnav-active');
        $('.site-header').css('position', 'relative');
        window.scrollTo(0, 0);
      });

      // content block close
      // js-gnav-util-close
      $closeTrigger.on('click', function (event) {
        $mobileNavOverlay.addClass('hidden');
        event.preventDefault();
        $(this).parent().addClass('hidden');
        $('.site-header').css('position', 'fixed');
      });
      $menucloseTrigger.on('click', function (event) {
        $menucloseTrigger.addClass('hidden');
        $menuTrigger.removeClass('hidden');
        $('body').toggleClass('mobile-gnav-active');
        $('.site-header').css('position', 'fixed');
      });
    }
  };
})(jQuery);
